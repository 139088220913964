import api from "../axios_service";

// combobox
export async function searchServicosPrestados(filtros) {
    const response = await api.get(
        `/servicos-prestados${filtros ? filtros : ""}`
    );
    return response.data.result;
}

export async function getServicoPrestado(id) {
    const response = await api.get(`/servicos-prestados/${id}`);
    return response.data.result;
}

export function putServicoPrestado(id, servico) {
    return api.put(`/servicos-prestados/${id}`, servico);
}

export function postServicoPrestado(servico) {
    return api.post("/servicos-prestados", servico);
}