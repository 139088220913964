<template>
  <div class="d-flex align-center">
    <v-autocomplete
      v-if="!loading"
      v-model="selectedServicoPrestado"
      :label="$tc('global.servicoRealizado')"
      :items="servicosPrestados"
      :loading="buscandoServicos"
      :search-input.sync="searchServico"
      dense
      outlined
      flat
      solo
      hide-details
      item-text="descricao"
      item-value="id"
      :placeholder="$tc('global.digitarbuscar')"
      append-icon=""
      full-width
      return-object
      no-filter
      :readonly="readonly"
      :clearable="!readonly"
      ><template v-slot:selection="data">
        <div class="pa-0">
          <div class="">{{ data.item.descricao }}</div>
        </div>
      </template>
    </v-autocomplete>
    <div class="ml-4">
      <v-btn
        v-if="selectedServicoPrestado"
        height="38px"
        width="48px"
        color="#4CAF50"
        x-small
        @click="goToEditServico"
      >
        <v-icon class="white--text">mdi-pencil</v-icon>
      </v-btn>
      <v-btn
        v-else
        depressed
        height="38px"
        width="48px"
        color="#4CAF50"
        x-small
        @click="goToAddServico"
      >
        <v-icon class="white--text">mdi-plus</v-icon>
      </v-btn>
    </div>
    <DialogServicoPrestado
      v-if="dialogServicoPrestado"
      :dialogServicoPrestado.sync="dialogServicoPrestado"
      :edit="edit"
      :servico_id="selectedServicoPrestado ? selectedServicoPrestado.id : null"
      @fetch-servicos-prestados="buscaServicosPrestados"
    />
  </div>
</template>

<script>
import { searchServicosPrestados } from "@/api/servicosPrestados/servicosPrestados.js";
export default {
  name: "ServicosPrestadosCombobox",

  components: {
    DialogServicoPrestado: () => import("./DialogServicoPrestado.vue"),
  },

  props: {
    servico_sync: {
      type: [Object, String],
    },
    servico_id: {
      type: [Number, String],
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: true,
      selectedServicoPrestado: null,
      servicosPrestados: [],
      buscandoServicos: false,
      searchServico: "",
      debounce: null,
      dialogServicoPrestado: false,
    };
  },

  watch: {
    selectedServicoPrestado() {
      if (this.selectedServicoPrestado) {
        this.$emit("update:servico_sync", this.selectedServicoPrestado);
      } else {
        this.$emit("update:servico_sync", null);
      }
    },
    searchServico() {
      if (this.buscandoServicos) return;
      this.buscaServicosPrestados();
    },
  },

  methods: {
    async searchServicosPrestados(filtro) {
      this.buscandoServicos = true;
      await searchServicosPrestados(filtro).then((response) => {
        this.servicosPrestados = response;
        if (this.servico_id) {
          this.selectedServicoPrestado = this.servicosPrestados.find((item) => {
            return item.id == this.servico_id;
          });
        }
      });
      this.buscandoServicos = false;
    },
    async buscaServicosPrestados(servico_id) {
      const filtro = `?search=${this.searchServico}`;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        await this.searchServicosPrestados(this.searchServico ? filtro : "");
        if (servico_id) {
          this.selectedServicoPrestado = this.servicosPrestados.find((item) => {
            return item.id == servico_id;
          });
          return;
        }
      }, 500);
    },
    goToAddServico() {
      this.edit = false;
      this.dialogServicoPrestado = true;
    },
    goToEditServico() {
      this.edit = true;
      this.dialogServicoPrestado = true;
    },
  },

  async mounted() {
    this.loading = false;
  },
};
</script>

<style></style>
